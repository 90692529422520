import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environment';
import { ProfileOrganization } from '@features/models/organization.model';
import { AddProfileDto, ProfileNameEmail, ProfileViewModel } from '@features/models/profile.model';
import { Result } from '@shared/models/result.model';
import { PROFILE_PICTURE_FORMAT } from '@shared/utils/constants';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private url = `${environment.apis.linkroom}/profiles`;

  constructor(private http: HttpClient) {}

  add(data: AddProfileDto) {
    return this.http.post<Result<ProfileViewModel>>(this.url, data);
  }

  update(id: string, data: Partial<AddProfileDto>) {
    return this.http.put<Result<ProfileViewModel>>(`${this.url}/${id}`, data);
  }

  get(id: string) {
    return this.http.get<Result<ProfileViewModel | undefined>>(`${this.url}/${id}`);
  }

  listOrganizations(profile: string) {
    return this.http.get<Result<ProfileOrganization[]>>(`${this.url}/${profile}/organizations`);
  }

  getPermissions(organizationId: string) {
    let params = new HttpParams().set('organization', organizationId);

    return this.http.get<Result<string | string[] | undefined>>(
      `${this.url}/permissions-description`,
      { params }
    );
  }

  getProfilesByOrganization(organizationId: string) {
    return this.http.get<Result<ProfileNameEmail[] | undefined>>(
      `${this.url}/organizations/${organizationId}`
    );
  }

  uploadPhoto(profile: string, blob: Blob) {
    const form = new FormData();
    form.append('file', blob, `${profile}.${PROFILE_PICTURE_FORMAT}`);

    return this.http.put<Result>(`${this.url}/upload-photo`, form, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getPhoto(profile: string) {
    return this.http.get<Result>(`${this.url}/${profile}/get-photo`);
  }

  deletePhoto() {
    return this.http.delete<Result>(`${this.url}/delete-photo`);
  }
}
